:root {
  --hover-colour: #ff2626;
  --white: #fff;
  --black: #000;
  --light-grey: #f7f7f7;
  --hover-colour-mono: #333;
  --app-height: 100%;
  --footer-colour: #d9d9d9;
}

.navbar-button {
  background-color: var(--white);
  font-weight: 600;
  border-radius: 50px;
  color: var(--black);
  padding-left: 25px;
  padding-right: 25px;
  border: none;
}

.navbar-button:hover {
  color: var(--white);
  background-color: var(--hover-colour);
  border: none;
}

.navbar-text {
  color: var(--white);
}

.landing-segment-container {
  min-height: 100vh;
  margin: 0px;
}

.landing-segment-background {
  position: absolute;
  z-index: -1;
  width: 60%;
  height: 90vh;
  border-top: 90vh solid var(--light-grey);
  border-right: 20vw solid transparent;
}

.landing-segment-image {
  object-fit: contain;
  width: 40vw;
}

.landing-segment-image-mobile {
  object-fit: contain;
  width: 96%;
  margin: 2%;
  margin-top: 50px;
}

.landing-segment-image-container {
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  align-content: center;
  margin-left: -10px;
}

.landing-segment-hero-inner-mobile {
  margin: 2%;
  text-align: center;
  margin-top: 0%;
  margin-bottom: 5%;
}

.landing-segment-title-mobile {
  font-size: 3rem;
  font-weight: 600;
  padding-top: 5%;
}

.landing-segment-title {
  font-size: 4.5rem;
  font-weight: 600;
}

.landing-segment-text {
  font-weight: 500;
}

.landing-segment-text-mobile {
  font-weight: 600;
  margin-top: 10px;
  text-align: justify;
}

.landing-segment-hero-inner {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 25%;
  width: 30%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.hero-button {
  background-color: var(--black);
  color: var(--white);
  margin-top: 30px;
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 50px;

  border: none;
}

.hero-button:hover {
  background-color: var(--hover-colour);
  color: var(--white);

  border: none;
}

.hero-button:active {
  background-color: var(--hover-colour);
  color: var(--white);

  border: none;
}

.footer {
  padding: 20px;
  background-color: var(--footer-colour);
  width: 100vw;
  min-height: 20vh;
}

.page-segment {
  width: 100vw;
}

.segment-header-container {
  text-align: center;
  width: 50%;
  margin: auto;
  margin-top: -25px;
  margin-bottom: 50px;
}

.segment-header-container-mobile {
  margin: 2%;
}

.segment-header-title {
  font-size: 4rem;
  font-weight: 600;
}

.segment-header-title-mobile {
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
}

.segment-header-text {
  font-weight: 500;
}

.segment-header-text-mobile {
  font-weight: 600;
  margin-top: 10px;
  text-align: justify;
}

.about-card {
  padding: 30px;
  padding-top: 50px;
  border-radius: 10px;
  text-align: center;
  width: 80%;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  min-height: 90%;
  margin-bottom: 25px;
  margin-top: 25px;
}

.about-card-mobile {
  padding: 30px;
  padding-top: 50px;
  border-radius: 10px;
  text-align: center;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  min-height: 90%;
  margin-bottom: 25px;
  margin-top: 25px;
}

.about-card-image {
  object-fit: contain;
  height: 90px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.about-card-title {
  font-weight: 600;
}

.about-card-text {
  width: 100%;
  margin: auto;
}

.about-card-row {
  margin-left: 2%;
  margin-right: 2%;
}

.about-card-row-mobile {
  margin: 2%;
}

.about-button {
  background-color: var(--black);
  color: var(--white);
  margin-top: 30px;
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 50px;
  width: 100%;

  border: none;
}

.about-button-container {
  width: 30%;
  margin: auto;
  margin-bottom: 50px;
}

.about-button-container-mobile {
  margin: 2%;
  margin-bottom: 50px;
}

.about-button:hover {
  background-color: var(--hover-colour);
  color: var(--white);

  border: none;
}

.about-button:active {
  background-color: var(--hover-colour);
  color: var(--white);

  border: none;
}

.vuprop-process-segment-container {
  height: 800px;
  margin: 0px;
}

.vuprop-process-segment-background {
  position: absolute;
  z-index: -1;
  width: 38%;
  height: 700px;
  border-top: 800px solid var(--light-grey);
  border-right: 20vw solid transparent;
}

.process-image {
  object-fit: contain;
  height: 250px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.process-image-mobile {
  object-fit: contain;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
}

.process-image-larger {
  object-fit: contain;
  height: 300px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.process-image-larger-mobile {
  object-fit: contain;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
}

.process-title {
  font-size: 3rem;
  font-weight: 600;
  position: relative;
  top: 35%;
  left: 30%;
}

.process-title-mobile {
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  background-color: var(--light-grey);
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.process-title-2 {
  font-size: 3rem;
  font-weight: 600;
  position: relative;
  top: 25%;
  left: 30%;
}

.process-header-container {
  text-align: center;
  width: 50%;
  margin: auto;
}

.process-header-container-mobile {
  text-align: center;
  margin: 2%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.contact-form {
  width: 70%;
  margin: auto;
  padding: 20px;
  border: 8px solid var(--black);
  border-radius: 25px;
}

.contact-form-mobile {
  padding: 20px;
  margin: 2%;
  border: 8px solid var(--black);
  border-radius: 25px;
}

.submit-button-container {
  width: 30%;
  margin: auto;
}

.white-icon {
  color: #fff;
}

.submit-button:hover {
  background-color: var(--hover-colour);
  color: var(--white);

  border: none;
}

.submit-button:active {
  background-color: var(--hover-colour);
  color: var(--white);

  border: none;
}

.submit-button {
  background-color: var(--black);
  color: var(--white);
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 50px;
  width: 100%;

  border: none;
}

.contact-segment-title {
  margin: 50px;
  margin-top: 20px;
  font-size: 4rem;
  font-weight: 600;
}

.contact-segment-icons-container {
  margin: 50px;
}

.footer-about {
  width: 70%;
}

.footer-about-mobile {
  margin-top: 20px;
  margin-bottom: 20px;
}

.footer-about-title-mobile {
  font-size: x-large;
  font-weight: 700;
  text-align: center;

  margin-bottom: 10px;
}

.footer-about-title {
  font-size: x-large;
  font-weight: 700;
}

.footer-about-text {
  font-size: medium;
  font-weight: 400;
}

.footer-about-text-mobile {
  font-size: medium;
  font-weight: 400;
  text-align: justify;
}

.footer-link {
  font-size: medium;
  font-weight: 400;
  cursor: pointer;
}

.footer-link:hover {
  text-decoration: underline;
  color: var(--black);
}

.footer-icon {
  font-size: x-large;
}

.footer-icon-container {
  cursor: pointer;
  font-size: medium;
  margin-top: 5px;
  margin-bottom: 5px;
}

.footer-icon-container:hover div {
  text-decoration: underline;
}

.footer-icon-container:hover {
  color: var(--black);
}

.no-decoration {
  text-decoration: none;
  color: var(--black);
}

.contact-icon {
  font-size: xx-large;
}

.contact-text {
  font-size: x-large;
}

.form-sent-text {
  text-align: center;
  font-weight: 500;
}
